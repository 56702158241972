/* eslint-disable */
import React from "react"
import Title from "../../Title"
import styles from "../../../css/confirm.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
const Confirm = () => {

  return (
    <section className={styles.activate}>
      <span className={styles.modalHeader}><Title title="step 2 of 2:" subtitle="Confirm this account update." /></span>
      <p className={styles.instructionsModal}>Check your email for your confirmation code and enter it below. Test</p>
      <div className={styles.center}>
        <form className={styles.form}>
          <div className={styles.row2}>
            <span className={styles.activation}><input
              type="text"
              name="activation"
              id="activation"
              placeholder="Confirmation Code"
              className={styles.formControl}
            /></span>
            <span className={styles.resend}><AniLink fade to='/activate/'>Resend Code</AniLink></span>
          </div>
          <div className={styles.arrowButton}>
            <input
              type="submit"
              value="submit"
              className={styles.submit}
              action="/activated"
            />
          </div>
        </form>
      </div>
    </section>
  )
}

export default Confirm
